'use client';

import { useCallback, useState } from 'react';
import { useSearchParams } from 'next/navigation';

import { InputIcon } from '@personly/libs-widgets';
import { magnifyIcon } from '@personly/libs-icons';
import { useValidators } from '@personly/libs-hooks';

import { useNavigation } from '../../hooks';

const parseQ = (searchParams) =>
  searchParams.has('q') ? searchParams.get('q') : '';

interface Props {
  languages: any;
  role?: string;
  placeholder?: string;
  validators: any;
}

const SearchTextInput = ({
  languages,
  role = 'card',
  placeholder = 'Search',
  validators,
}: Props) => {
  const searchParams = useSearchParams();
  const { range } = useValidators(validators);
  const { updateQueryParams } = useNavigation(languages);

  const [q, setQ] = useState(parseQ(searchParams));
  const searchMinMax = range('SEARCH');

  const onClick = useCallback(() => {
    const queryParams = new URLSearchParams();
    queryParams.set('q', q);
    queryParams.set('page', '1');

    updateQueryParams(queryParams);
  }, [q, updateQueryParams]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') onClick();
    },
    [onClick]
  );

  const onChange = useCallback((e) => setQ(e.target.value), []);

  return (
    <InputIcon
      role={role}
      type={'text'}
      placeholder={placeholder}
      name="search"
      id="search"
      value={q}
      maxLength={searchMinMax.max}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      icon={magnifyIcon}
      label="Begin Search"
      onClick={onClick}
    />
  );
};

export default SearchTextInput;

import Picture from '../picture';

const makeSrcSets = (fileSources) =>
  Object.keys(fileSources).reduce((acc, schemeHandle) => {
    const { fileName, metadata } = fileSources[schemeHandle];
    const { height, width } = metadata;
    const src = `/images/organizations/${fileName}`;
    return { ...acc, [schemeHandle]: { src, height, width, alt: fileName } };
  }, {});

interface Props {
  fileSources: any;
}

const OrganizationImage = ({ fileSources }: Props) => (
  <Picture
    srcSets={makeSrcSets(fileSources)}
    className="h-auto w-full"
    sizes="100vw"
  />
);

export default OrganizationImage;
